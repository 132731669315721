<template>
    <div>   
        <HeaderBg title="إشعارات الموبايل" />

        <div class="form_container">
            <form @submit.prevent="addFcm">
                <div class="input_container">
                    <label> العنوان</label>
                    <input type="text" v-model="fcm.title">
                </div>
                <div class="input_container">
                    <label> الرسالة </label>
                    <textarea type="text" v-model="fcm.body"></textarea>
                </div>
                <div class="alert alert-success mt-2" role="alert" v-if="requestSuccess == true">
                    <p> تم إرسال الإشعار بنجاح </p>
                </div>
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">حفظ</button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import store from "@/store/index.js";
import axios from 'axios';
export default {
    name: 'MobileNotification',
    components: {HeaderBg},
    data(){
        return{
            postLoaded: false,
            requestSuccess: false,
            fcm:{
                title: '',
                body: ''
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    methods:{
        addFcm(){
            this.postLoaded = true;
            axios.post(`${store.state.domain}/admin/notifications/fcm/users`, this.fcm, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.postLoaded = false
                this.requestSuccess = true
                this.fcm.title = ''
                this.fcm.body = ''
                setTimeout(() => {
                    this.requestSuccess = false
                }, 3000)
            })  
            .catch(err =>  {
                this.errors = err.response.data.errors;
                this.ErrorCheck = true;
                this.postLoaded = false;
                
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 100px;
    width: 482px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    form{
        > div{
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input,
            textarea{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 100%;
            }
            textarea{
                min-height: 200px;
            }
        }
        button{
            margin: 30px auto 0 auto;
            display: block;
        }
    }
}
</style>